/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { FaEnvelope, FaGithub, FaLinkedin } from "react-icons/fa"
import { useTheme } from "../utils/theme"

const Bio = () => {
  const theme = useTheme()
  const { rhythm } = theme
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      githubLogo: file(absolutePath: { regex: "/github-logo.png/" }) {
        childImageSharp {
          fixed(width: 32, height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      linkedinLogo: file(absolutePath: { regex: "/linkedin-logo.png/" }) {
        childImageSharp {
          fixed(width: 32, height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
            email
          }
          social {
            linkedin
            github
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        marginBottom: rhythm(2.5),
      }}
    >
      <Image
        fixed={data.avatar.childImageSharp.fixed}
        alt={author.name}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0,
          minWidth: 100,
          borderRadius: `100%`,
        }}
        imgStyle={{
          borderRadius: `50%`,
        }}
      />
      <div>
        <p
          style={{
            marginBottom: rhythm(1 / 4),
          }}
        >
          Written by <strong>{author.name}</strong> {author.summary}
        </p>
        <a
          style={{
            marginRight: rhythm(1 / 2),
            boxShadow: "none",
            width: 32,
            height: 32,
          }}
          href={`mailto:${author.email}`}
        >
          <FaEnvelope title="Email" size="32" />
        </a>
        <a
          style={{
            boxShadow: "none",
            marginRight: rhythm(1 / 2),
          }}
          rel="noreferrer"
          target="_blank"
          href={`${social.linkedin}`}
        >
          <FaLinkedin title="LinkedIn" size="32" />
        </a>
        {` `}
        <a
          style={{
            boxShadow: "none",
            marginLeft: -2,
          }}
          rel="noreferrer"
          target="_blank"
          href={`${social.github}`}
        >
          <FaGithub title="Github" size="32" />
        </a>
      </div>
    </div>
  )
}

export default Bio
